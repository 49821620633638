import HubspotContactForm from '../components/HubspotContactForm'
import bullseye1 from '../images/bullseye1.png';
import bullseye2 from '../images/bullseye2.png';
import bullseye3 from '../images/bullseye3.png';
import bullseye4 from '../images/bullseye4.png';
import '../index.css';
import CustomLink from '../components/CustomLink';

function ResultsModal({result}) {
  return (
    <div  
        className="absolute top-0 left-0 w-screen min-h-screen bg-ac-dark-blue flex justify-center items-center flex-col font-opensans"
    >
        <div className="py-20 w-full md:w-3/5 flex flex-col items-center gap-4">
            <h1 className='text-center text-xl md:text-2xl capitalize text-white'>{result.label}</h1>
            <div className='flex justify-center w-full'>
                {result.level === 4 && <img src={bullseye4} alt="a four ring bullseye with the center filled in" className='inline-block w-[400px] sm:w-[600px] md:w-[700px] p-1'/>}
                {result.level === 3 && <img src={bullseye3} alt="a four ring bullseye with the second ring filled in" className='inline-block w-[400px] sm:w-[600px] md:w-[700px] p-1'/>}
                {result.level === 2 && <img src={bullseye2} alt="a four ring bullseye with the third outer ring filled in" className='inline-block w-[400px] sm:w-[600px] md:w-[700px] p-1'/>}
                {result.level === 1 && <img src={bullseye1} alt="a four ring bullseye with the outer ring filled in" className='inline-block w-[400px] sm:w-[600px] md:w-[700px] p-1'/>}
            </div>
            <HubspotContactForm label={result.label} body={result.description} />
            <hr />
            <div id="readmore" className="results p-4 w-9/10 sm:w-5/6 max-w-3xl self-center text-white">
                <p className='text-center'>
                    {result.description}<br /><br />
                    {result.url}
                </p>
                <hr className='mb-4'/>
                <p className='text-center'>
                    In conclusion, the mental health bullseye offers a comprehensive framework for understanding the diverse experiences along the continuum. Exploring innovative approaches, including psychedelic treatments, becomes crucial, especially in cases of treatment-resistant mental illness. Recognizing the interconnectedness of mental well-being, burnout, mental illness diagnosis, and treatment-resistant challenges provides a holistic perspective for addressing the complexities of the mental health journey. Ask us how to gain access to the care that is right for you.<br /><br />
                </p>
                <div className="flex justify-center">
                    <CustomLink blue to="https://atmacena.com">Visit Us</CustomLink>
                </div>
            </div>
           
        </div>
    </div>
  );
}

export default ResultsModal;
