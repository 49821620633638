import React, {useEffect} from "react";

const HubspotContactForm = ({ label, body }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='//js.hsforms.net/forms/embed/v2.js'
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    target: '#hubspotForm',
                    region: "na1",
                    portalId: "22225195",
                    formId: "18429eb3-3a6c-43e0-b65e-3457bb1d0144"
                })
            }
        });
    }, []);


    window.addEventListener("message", function(event){
        if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
            const formDocument = document.getElementById('hs-form-iframe-0').contentDocument
            if (!formDocument) return
            formDocument.querySelector('input[name="quiz_result_label"]').value = label
            formDocument.querySelector('input[name="quiz_result_label"]').dispatchEvent(new Event('change'))
            formDocument.querySelector('input[name="quiz_result_body"]').value = body
            formDocument.querySelector('input[name="quiz_result_body"]').dispatchEvent(new Event('change'))
         }
     });

    return (
        <div className="py-20 px-6 sm:px-5 w-full md:w-3/5">
            <p className="font-bold text-white">Send me my results!</p>
            <div className="w-full" id="hubspotForm"></div>
        </div>
    );

}

export default HubspotContactForm;