import '../index.css';
import Question from '../components/Question';
import Button from '../components/Button';
import Container from '../components/Container';
import { useState } from 'react';
import questions from '../questions.json'
import results from '../results.json'
import allScores from '../scores.json'
import ResultsModal from './ResultsModal';

function Quiz() {
    const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)
    const [currentSelection, setCurrentSelection] = useState({})
    const [isLastQuestion, setIsLastQuestion] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [result, setResult] = useState({})
    const [showResult, setShowResult] = useState(false)

    const handleNext = (e) => {
        e.preventDefault()
       
        questions[currentQuestionIdx].valueSelected = currentSelection.value

        if (currentQuestionIdx === (questions.length - 2)) {
            setIsLastQuestion(true)
        }

        setCurrentSelection(null)
        setCurrentQuestionIdx(currentQuestionIdx + 1)
        setDisabled(true)
    }

    const handleBack = (e) => {
        e.preventDefault()
        // TODO get selected val of prev and set to checked
        setCurrentQuestionIdx(currentQuestionIdx - 1)
        setCurrentSelection(null)
        if (currentQuestionIdx < (questions.length - 1)) {
            setIsLastQuestion(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)

        const total = questions.reduce(
            (accumulator, currentValue) => accumulator + currentValue.valueSelected,
            0
        );

        getResult(total)
        setShowResult(true)
    }

    const handleSelect = (e) => {
        const scores = allScores.scores
        setDisabled(false)
        const scoreIndex = scores.findIndex(score => score.value === Number(e.target.value))
        scores[scoreIndex].isChecked = !scores[scoreIndex].isChecked
        setCurrentSelection(scores[scoreIndex])
    }

    const getResult = (total) => {
        if (total < 15) {
            setResult(results[0])
        } else if (total >= 15 && total <= 29) {
            setResult(results[1])
        } else if (total >= 30 && total <= 44) {
            setResult(results[2]) 
        } else {
            setResult(results[3])
        }
    }

 
    return (
        <Container>
            <form className='py-16 px-8 mx-5 md:w-3/4 max-w-4xl flex flex-col justify-center items-center gap-8 bg-white/90'>
                <Question handleSelect={handleSelect} currentSelection={currentSelection} question={questions[currentQuestionIdx]?.title} scores={currentQuestionIdx > 14 ? allScores.reverseScores : allScores.scores} />
                <div className='flex gap-6 mt-16'>
                    {currentQuestionIdx !== 0 && <Button onClick={(e) => handleBack(e)}>Back</Button>}
                    <Button disabled={disabled} onClick={(e) => isLastQuestion ? handleSubmit(e) : handleNext(e)}>{isLastQuestion ? 'Finish' : 'Next'}</Button>
                </div>
            </form>
            {showResult && <ResultsModal result={result} />}
        </Container>
    );
}

export default Quiz;
