import Container from './components/Container';
import CustomLink from './components/CustomLink';
import './index.css';

function App() {

  return (
    <Container>
      <div className="py-10 px-4 sm:px-10 w-5/6 max-w-3xl flex flex-col gap-8 bg-white/90">
        <h1 className='text-center text-xl md:text-2xl'>Psychedelics and the Mental Health Continuum</h1>
        <hr />
        <p className='self-center text-center text-sm md:text-base'>Consider the mental health continuum like the rings of a bullseye, illustrating the diverse states an individual may experience throughout the course of their life. <br />
          Each ring encapsulates a specific spectrum of experiences, from the outermost circle of mental well-being and personal optimization to the central-most challenge of treatment-resistant mental illness. <br /><br />
          At each layer, there is a potential role for psychedelic treatments that unveils itself as a nuanced consideration in promoting recovery, mental health, and well-being in an individual’s cognitive health evolution.
        </p>
        <hr />
        <CustomLink to="/disclaimer">Begin</CustomLink>
      </div>
    </Container>
  );
}

export default App;
