import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from 'react';
import App from './App';
import QuizPage from './pages/QuizPage'
import DisclaimerPage from './pages/DisclaimerPage'
import './index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/psychedelics-and-the-mental-health-continuum",
    element: <QuizPage />
  },
  {
    path: "/disclaimer",
    element: <DisclaimerPage />
  }
])

// Find all widget divs
const widgetDivs = document.querySelectorAll('.self-assessment-widget');

// Inject our React App into each class
widgetDivs.forEach(div => {
    const root = createRoot(div); 
    root.render(
      <RouterProvider router={router}>
        <App symbol={div.dataset.symbol}/>
      </RouterProvider>
    );
});