import { Link } from 'react-router-dom';
import '../index.css';

function CustomLink({children, to, blue}) {
  return (
    <Link to={to} className={`self-center w-fit px-12 py-2 text-white border border-1 uppercase font-medium hover:bg-white transition-all duration-500 ${blue ? 'hover:text-ac-dark-blue border-ac-light-blue bg-ac-light-blue' : 'hover:text-ac-dark-green border-ac-dark-green bg-ac-dark-green'}`}>{children}</Link>
    );
}

export default CustomLink;
